import $ from "jquery";
import {active} from "./const.js";

$('.js-displayChangeTrigger').hover(
  (event) => {
    $('.js-displayChangeTop').removeClass(active);
    $('.js-displayChangeTrigger').removeClass(active);
    $(event.currentTarget).addClass(active);
    let index = $('.js-displayChangeTrigger').index(event.currentTarget);;
    $('.js-displayChangeTarget').removeClass(active);
    $('.js-displayChangeTarget').eq(index).addClass(active);
    if($(event.currentTarget).closest('.js-displayChangeTop').length) {
      $('.js-displayChangeTop').addClass(active);
    }
  }
);
