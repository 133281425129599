import $ from "jquery";
import {active} from "./const.js";
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

let swiper = new Swiper(".js-homeMv", {
  autoplay: {
    delay: 4000,
    disableOnInteraction: false,
  },
  effect: "fade",
  loop: true,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  spaceBetween: 0,
  speed: 2000,
});
