import $ from "jquery";
import {active} from "./const.js";

$('#js-hamburger').on('click', (event) => {
  $(event.currentTarget).toggleClass(active);
  if($(event.currentTarget).hasClass(active)) {
    $('body').css('overflow', 'hidden');
  } else {
    $('body').css('overflow', 'visible');
    $('.js-spLocalNav').removeClass(active);
  }
  $("#js-spNav").toggleClass(active);
});

$('#js-spLangButton').on('click', (event) => {
  $('#js-spLangMenu').fadeToggle();
});

$('.js-spNavItem').on('click', (event) => {
  let spNavItem = $(event.currentTarget).attr('id');
  if(spNavItem === 'js-spNavItemCompany') {
    $('#js-spLocalNavCompany').addClass(active);
  } else if(spNavItem === 'js-spNavItemBusiness') {
    $('#js-spLocalNavBusiness').addClass(active);
  }
});

$('.js-spNavBackButton').on('click', (event) => {
  $('.js-spLocalNav').removeClass(active);
});

$('#js-spNavSearchInput input[type=text]').focus(() =>{
  $('#js-spLangMenu').fadeOut();
});
