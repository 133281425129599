import $ from "jquery";
import {active} from "./const.js";

const tab = '.js-tab',
      tabContent = '.js-tabContent';

// タブ切り替え
$(tab).on('click', (event) => {
  $(tab).removeClass(active);
  $(event.currentTarget).addClass(active);
  let index = $(tab).index(event.currentTarget);
  $(tabContent).removeClass(active);
  $(tabContent).eq(index).addClass(active);
  return false;
});

// ページ内にタブがあり、URLにハッシュがある場合、該当するタブを表示する
if($(tab).length) {
  let hash = $(location).prop('hash').substr(1);
  if(hash) {
    $(tab).removeClass(active);
    $(tabContent).removeClass(active);
    $('.js-' + hash).addClass(active);
  }
}

// ページ内リンクでタブの切り替え
$('.js-tab2').on('click', (event) => {
  event.preventDefault();
  let href = $(event.currentTarget).attr('href');
  const baseUrl = window.location.origin;
  window.location.href = baseUrl + href;
  setTimeout(function() {
    localStorage.setItem('scrollToTop', 'true');
    window.location.reload();
  }, 100);
});

window.onload = function() {
  if(localStorage.getItem('scrollToTop') === 'true') {
    setTimeout(function() {
      const ele = document.getElementById('js-body');
      ele.scrollIntoView();
      localStorage.removeItem('scrollToTop');
    }, 10);
  }
}
