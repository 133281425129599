import $ from "jquery";
import {active} from "./const.js";

// タブ切り替え
$('.js-accordion').on('click', (event) => {
  const $accordion = $(event.currentTarget);
  const $accordionContent = $accordion.next('.js-accordionContent');
  const $span = $accordion.find('span');

  $accordion.toggleClass(active);
  $accordionContent.slideToggle();

  // spanのテキストを切り替える
  if ($span.text() === '詳しく見る') {
    $span.text('閉じる');
  } else {
    $span.text('詳しく見る');
  }

  return false;
});
