//サイト内検索
var cx = '014696113997316299510:8kxx2szrk8w'; // カスタム検索エンジンID
var gcse = document.createElement( 'script' );
gcse.type = 'text/javascript';
gcse.async = true;
gcse.src = ( document.location.protocol == 'https:' ? 'https:' : 'http:' )
    + '//www.google.com/cse/cse.js?cx=' + cx;
var s = document.getElementsByTagName( 'script' )[ 0 ];
s.parentNode.insertBefore( gcse, s );

import $ from "jquery";
import {active} from "./const.js";

$('#js-searchButton').on('click', (event) => {
  $('#js-search').fadeIn();
});

$('#js-searchClose').on('click', (event) => {
  $('#js-search').fadeOut();
});
