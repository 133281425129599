import $ from "jquery";
import {active} from "./const.js";

const ref = document.referrer;

function removeProtocolAndDomainFromUrl(url) {
  const parser = document.createElement('a');
  parser.href = url;
  return parser.pathname + parser.search + parser.hash;
}

const modifiedUrl = removeProtocolAndDomainFromUrl(ref);

if(modifiedUrl == "/company/global/"){
  $('#js-backGlobal').attr('href', '/company/global/');
}
